import { cloneDeep } from 'lodash';
import { IRestaurant } from '@clubpay/customer-common-module/src/repository/vendor/type';

interface ID {
    id?: any;
    code?: any;
}

interface IPromise {
    resolve: any;
    reject: any;
}

export interface Many<T> {
    rows: T[];
    count?: number;
    scrollId?: string;
    hasMorePage?: boolean;
    _cache?: boolean;
}

interface IOptions {
    useRequestCache?: boolean;
    useItemCache?: boolean;
    simMode?: boolean;
}

interface IOptionsFull extends IOptions {
    keyFn?: (params: any) => string;
}

export class SingleFlight<T extends ID> {
    private singleFlightMap: { [key: string]: IPromise[] } = {};

    private cache: { [key: string]: T } = {};

    private requestCache: { [key: string]: Many<T> } = {};

    private readonly keyFn: (params: any) => string = (params: any) => {
        const { payload, ...rest } = params;
        return JSON.stringify({
            ...rest,
            ...(payload
                ? {
                      payload: {
                          cc: payload.cc,
                          slug: payload.slug,
                          id: payload.id,
                          f1: payload.f1,
                          f2: payload.f2,
                          hash: payload.hash,
                      },
                  }
                : {}),
        });
    };

    private fetchManyFn?: (params: any) => Promise<Many<T>>;

    private fetchOneFn?: (params: any) => Promise<T>;

    private options: IOptions = {};

    constructor(
        fetchFns: {
            many?: (params: any) => Promise<Many<T>>;
            one?: (params: any) => Promise<T>;
        },
        options?: IOptionsFull,
    ) {
        this.fetchManyFn = fetchFns.many;
        this.fetchOneFn = fetchFns.one;
        if (options?.keyFn) {
            this.keyFn = options.keyFn;
        }
        this.options = {
            ...options,
        };
    }

    public getMany(params: any): Promise<Many<T>> {
        if (!this.fetchManyFn) {
            return Promise.reject(new Error('fetch many function is not being set'));
        }
        if (this.options?.simMode) {
            const simKey = params.hashId ? `sim_${params.hashId}` : 'sim';
            return new Promise((resolve) => {
                setTimeout(
                    () => {
                        const res = cloneDeep(this.requestCache[simKey]);
                        if (res) {
                            res._cache = true;
                        }
                        resolve(res);
                    },
                    this.requestCache.hasOwnProperty(simKey) ? 0 : 1000,
                );
            });
        }
        console.log({ params });
        const key = `m_${this.keyFn(params)}`;
        if (this.options.useRequestCache && this.requestCache.hasOwnProperty(key)) {
            const res = cloneDeep(this.requestCache[key]);
            res._cache = true;
            return Promise.resolve(res);
        }
        return this.get<Many<T>>(this.fetchManyFn, params, key, (res) => {
            this.requestCache[key] = cloneDeep(res);
            if (this.options.useItemCache) {
                this.setMany(res.rows);
            }
            return res;
        });
    }

    public getOne(id: any, payload: any, menuId: any, vendor?: IRestaurant): Promise<T> {
        if (!this.fetchOneFn) {
            return Promise.reject(new Error('fetch one function is not being set'));
        }
        if (this.options.useItemCache && this.cache.hasOwnProperty(id)) {
            return Promise.resolve(cloneDeep(this.cache[id]));
        }
        return this.get<T>(this.fetchOneFn, { id, payload, menuId, vendor }, id, (res) => {
            this.cache[id] = cloneDeep(res);
            return res;
        });
    }

    public getManyCache(ids: any[]): Array<T | undefined> {
        if (!this.options.useItemCache) {
            return Array.from({ length: ids.length });
        }
        return ids.map((id) => {
            const item = this.cache[id];
            if (item) {
                return cloneDeep(item);
            }
            return undefined;
        });
    }

    public setOne(item: T) {
        if (item.id || item.code) {
            this.cache[item.id || item.code] = item;
        }
    }

    public setMany(items: Array<T>, sim?: string | boolean) {
        if (sim) {
            this.requestCache[sim === true ? 'sim' : `sim_${sim}`] = { rows: cloneDeep(items) };
        }
        items?.forEach((item) => {
            if (item.id || item.code) {
                this.cache[item.id || item.code] = item;
            }
        });
    }

    public clearRequestCaches() {
        this.requestCache = {};
    }

    public clearItemCaches() {
        this.cache = {};
    }

    public clearAllCaches() {
        this.clearRequestCaches();
        this.clearItemCaches();
    }

    private get<P>(fn: any, params: any, key: string, middleware: (arg: P) => P): Promise<P> {
        return new Promise((resolve, reject) => {
            if (this.singleFlightMap.hasOwnProperty(key)) {
                this.singleFlightMap[key].push({
                    resolve,
                    reject,
                });
            } else {
                this.singleFlightMap[key] = [
                    {
                        resolve,
                        reject,
                    },
                ];
                fn(params)
                    .then((res: any) => {
                        return middleware(res);
                    })
                    .then((res: any) => {
                        this.singleFlightMap[key].forEach((promise) => {
                            promise.resolve(cloneDeep(res));
                        });
                    })
                    .catch((err: any) => {
                        this.singleFlightMap[key].forEach((promise) => {
                            promise.reject(err);
                        });
                    })
                    .finally(() => {
                        delete this.singleFlightMap[key];
                    });
            }
        });
    }
}
